:root {
    --homepage-banner-text-color: #{$white};
}

.homepage-banner-bargain {
    position: relative;
    max-width: var(--content-wrapper-width);
    margin: 50px auto;
    overflow: hidden;
    @include from-tablet {
        padding-left: var(--content-padding);
        padding-right: var(--content-padding);
    }
    img {
        @include mobile {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
            width: auto;
        }
    }

    &-box {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include desktop {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        @include tablet {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        @include mobile {
            position: static;
            padding: 40px 0;
            transform: none;
        }
    }

    p {
        line-height: 42px;
        font-weight: 700;
        letter-spacing: 3px;
        text-align: center;
        font-family: $font-primary;
        color: var(--homepage-banner-text-color);
        font-size: 24px;

        &:last-child:not(:first-child) {
            margin-bottom: 0;
        }

        @include desktop {
            font-size: 30px;
            margin-bottom: 30px;
        }

        @include mobile {
            font-size: 20px;
            line-height: 32px;
            padding: 0 10px;
        }
    }

    .Button {
        box-shadow: none;
        font-family: $font-primary;
        letter-spacing: 0.07em;
        font-weight: bold;
        font-size: 15px;
        line-height: 140%;
        @include desktop {
            min-width: 197px;
        }

        @include mobileAndTablet {
            font-size: 16px;
            --button-height: 40px;
            --button-hover-height: 40px;
            min-width: 167px;
        }
    }
}
