.daily-promo-and-products {
    margin: 80px auto 0 !important;
    max-width: var(--content-wrapper-width);
    padding-left: var(--content-padding);
    padding-right: var(--content-padding);
    &__content {
        @include desktop {
            display: block;
            margin-top: 40px;
        }
    }

    &.ProductsSlider {
        .ProductsSlider {
            padding: 0;
        }

        .AdvoxSlider {
            border-top: 0;
            margin-top: 0;
            padding: 40px 0 0;
        }
    }

    &.PromoWithinSlider {
        .daily-promo-and-products__content {
            @include desktop {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;

                & > *:first-child.ProductsSections {
                    width: 100%;
                }
            }
        }

        .DailyPromoProduct {
            @include desktop {
                width: 40%;
                min-width: 40%;
                max-width: 40%;
                z-index: 1;
            }

            @include ultra-narrow-desktop {
                width: 50%;
                min-width: 50%;
                max-width: 50%;
            }

            &-Content {
                @include desktop {
                    width: 100%;
                    min-width: 100%;
                    max-width: 100%;
                }
            }

            &-ImgWrapper {
                @include desktop {
                    width: 50%;
                    min-width: 120px;
                    max-width: 230px;
                }
            }
        }
    }

    &.ProductsList {
        @include mobileAndTablet {
            padding: 0;

            .ProductsSlider {
                padding: 0;
            }

            .AdvoxSlider {
                border-top: 0;
                margin-top: 0;
                padding: 40px 0 0;
            }
        }

        .daily-promo-and-products__content {
            @include desktop {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 25px 15px;
            }
        }

        .DailyPromoProduct {
            @include desktop {
                width: calc(40% - 15px);
                min-width: calc(40% - 15px);
                max-width: calc(40% - 15px);
            }

            @include ultra-narrow-desktop {
                width: calc(50% - 15px);
                min-width: calc(50% - 15px);
                max-width: calc(50% - 15px);
            }

            &-Content {
                @include desktop {
                    width: 100%;
                    min-width: 100%;
                    max-width: 100%;
                }
            }

            &-ImgWrapper {
                @include desktop {
                    width: 50%;
                    min-width: 120px;
                    max-width: 230px;
                }
            }
        }

        .ProductCard.ProductCard_layout_grid {
            @include desktop {
                max-width: calc(20% - 15px);
                min-width: calc(20% - 15px);
            }

            @include ultra-narrow-desktop {
                max-width: calc(25% - 15px);
                min-width: calc(25% - 15px);
            }
        }
    }

    .AdvoxSlider-Heading {
        font-size: 30px;
        line-height: 34px;
        margin: 10px auto;

        @include desktop {
            margin: 10px auto 50px;
        }
    }

    .DailyPromoProduct {
        align-items: center;
        margin-top: 42px;

        @include from-tablet {
            margin-top: 50px;
        }
        @include desktop {
            align-items: flex-start;
            margin: 0;
        }
    }

    .DailyPromoProduct + .AdvoxCatalogWidget.ProductList {
        padding-bottom: 0;

        @include desktop {
            width: 60%;
        }

        @include ultra-narrow-desktop {
            width: 50%;
        }

        .AdvoxSlider {
            padding-top: 0;

            @include desktop {
                max-width: 100%;
            }
        }

        .ProductsSlider .AdvoxSlider {
            @include wide-desktop {
                --slider-height: 390px;
                --slider-height-tablet: 390px;
                --slider-height-desktop: 390px;
            }

            .slick-list {
                margin: 0;
            }

            .slick-prev {
                @include desktop {
                    left: -66%;
                }

                @include ultra-narrow-desktop {
                    left: -100%;
                }

                @include wider-desktop {
                    left: -72%;
                }
            }
            .slick-next {
                @include wide-desktop {
                    right: 0;
                }
                @include wider-desktop {
                    right: -7px;
                }
                @include widest-desktop {
                    right: -57px;
                }
            }
            .slick-slide {
                > div {
                    @include desktop {
                        padding: 0 0 30px;
                    }
                }
            }
        }
    }
}
