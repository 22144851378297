.daily-promo-and-products {
    &__content {
        @include desktop {
            margin-top: 0;
        }
    }
    .AdvoxSlider-Heading {
        text-align: left;
        font-size: 22px;
        line-height: 32px;
    }

    &.PromoWithinSlider {
        .DailyPromoProduct {
            @include desktop {
                margin-bottom: 0;
            }
        }
    }
}
