@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.PPSform {
    &-Heading {
        display: inline-block;
    }

    &-Form {
        .FieldForm-Fields {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            fieldset {
                width: 30%;

                input {
                    width: 100%;
                }

                &.Fieldset {
                    &-type_textarea,
                    &-type_file {
                        width: 100%;
                        textarea {
                            width: 100%;
                        }
                    }

                    &-type_file {
                        margin-top: 20px;

                        > label {
                            border: 1px dashed $grey3;
                            border-radius: 5px;
                            padding: 20px 20px;
                            text-align: center;
                            cursor: pointer;

                            .Field-LabelContainer {
                                label {
                                    justify-content: center;
                                }
                            }
                        }

                        > span {
                            font-size: 18px;
                            padding: 20px 0;
                        }
                    }

                    &-type_checkbox {
                        width: 100%;
                    }

                    &-type_select {
                        &_fullWidth {
                            width: 100%;
                        }
                    }
                }

                input,
                textarea {
                    &::placeholder {
                        color: $grey3 !important;
                    }
                }
            }
        }
    }
    &-Button {
        margin: 40px auto 100px 50%;
        transform: translate(-50%, 0);
        text-align: center;
    }

    &-Label {
        p {
            font-size: 16px;
            margin: 5px;
        }

        span {
            font-size: 13px;
            text-transform: none;
            font-weight: normal;
            border-bottom: none !important;
        }
    }
}
