@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.HeroSlider {
    &-Wrapper {
        background: $grey10;
        @include from-tablet {
            > div {
                padding: 24px 0;
            }
        }
    }
    &-SlideHeader {
        text-align: left;
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;

        @include from-tablet {
            font-size: 22px;
            line-height: 32px;
        }
        @include desktop {
            font-size: 56px;
            line-height: 64px;
            max-width: 445px;
        }
    }
    &-CTAButtonWrapper {
        margin-top: 20px;
        @include from-tablet {
            margin-top: 0;
        }
    }
    &-CTAButton {
        background: transparent;
        border: 1px solid $white;

        @include mobile {
            width: 100%;
        }
    }

    &-SlideImage {
        img {
            object-position: bottom;
        }
    }

    &-SlideContent {
        background: $default-primary-base-color;
        text-align: left;

        @include from-tablet {
            padding: 25px 40px 24px;
            max-width: 276px;
        }

        @include desktop {
            max-width: 536px;
            min-width: 536px;
            padding: 25px 68px 24px;
        }
    }

    &-SlideContainer {
        @include from-tablet {
            border-radius: 0;
            overflow: hidden;
        }
    }
}
