@import '../../style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../style/abstract/button';

.SearchField {
    &-Input {
        border-color: rgba(27, 42, 80, 0.2);
    }
    &-CloseIcon {
        svg path {
            fill: $blue50;
        }
    }
    &-SearchIcon {
        svg path {
            fill: $blue50;
            stroke: $blue50;
        }
    }
}
