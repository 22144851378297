@font-face {
    font-family: 'Switzer';
    src: url(../../../../advox-strigo-b2b/src/style/fonts/Switzer-Medium.woff2) format('woff2'),
        url(../../../../advox-strigo-b2b/src/style/fonts/Switzer-Medium.woff) format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Switzer';
    src: url(../../../../advox-strigo-b2b/src/style/fonts/Switzer-Bold.woff2) format('woff2'),
        url(../../../../advox-strigo-b2b/src/style/fonts/Switzer-Bold.woff) format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(../../../../advox-basic/src/style/fonts/roboto-regular-webfont.woff2) format('woff2'),
        url(../../../../advox-basic/src/style/fonts/roboto-regular-webfont.woff) format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(../../../../advox-basic/src/style/fonts/roboto-bold-webfont.woff2) format('woff2'),
        url(../../../../advox-basic/src/style/fonts/roboto-bold-webfont.woff) format('woff');
    font-weight: 700;
    font-style: normal;
}

:root {
    --font-switzer: 'Switzer', sans-serif;
    --font-roboto: 'Roboto', sans-serif;
    --primary-font-weight-normal: 600;
    --primary-font-weight-bold: 700;
    --secondary-font-weight-normal: 400;
    --secondary-font-weight-bold: 700;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-switzer);
}
