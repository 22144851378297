@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

$colors: (
    datepicker__background-color: $grey20,
    datepicker__border-color: $grey40,
    datepicker__highlighted-color: $green50,
    datepicker__muted-color: $grey40,
    datepicker__selected-color: $blue50,
    datepicker__text-color: $black,
    datepicker__header-color: $black,
    datepicker__navigation-disabled-color: $grey30,
);

$datepicker__background-color: map-get($colors, datepicker__background-color);
$datepicker__border-color: map-get($colors, datepicker__border-color);
$datepicker__highlighted-color: map-get($colors, datepicker__highlighted-color);
$datepicker__muted-color: map-get($colors, datepicker__muted-color);
$datepicker__selected-color: map-get($colors, datepicker__selected-color);
$datepicker__text-color: map-get($colors, datepicker__text-color);
$datepicker__header-color: map-get($colors, datepicker__header-color);
$datepicker__navigation-disabled-color: map-get($colors, datepicker__navigation-disabled-color);

$datepicker__border-radius: 0;
$datepicker__day-margin: 4px;
$datepicker__font-size: 12px;
$datepicker__font-family: 'Helvetica Neue', helvetica, arial, sans-serif;
$datepicker__item-size: 24px;
$datepicker__margin: 4px;
$datepicker__navigation-button-size: 32px;
$datepicker__triangle-size: 8px;

@import '~react-datepicker/src/stylesheets/datepicker';
