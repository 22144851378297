@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --checkout-step-font: #{$font-primary};
    --checkout-step-title-color: #{$grey4};
    --checkout-step-count-background: #{$grey4};
    --checkout-step-count-color: #{$white};
    --checkout-step-separator-background: #{$grey4};
    --checkout-step-active-title-color: #{$blue50};
    --checkout-step-active-count-background: #{$orange50};
}
.RmaView {
    @include desktop {
        margin-top: -32px;
    }
    &-Heading {
        font-family: $font-primary;
        font-weight: bold;
        font-size: 30px;
        line-height: 140%;
        letter-spacing: 0.03em;
        color: $default-primary-base-color;
        margin: 0;
        span {
            color: $grey-dark;
        }
    }
    &-StatusSteps {
        counter-reset: checkoutSteps;
        display: flex;
        @include from-tablet {
            padding: 24px 0;
        }

        @include mobile {
            padding: 16px 0;
        }
    }

    &-RmaInfo {
        margin-top: 15px;
        @include from-tablet {
            margin-top: 26px;
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
        }

        @include desktop {
            display: block;
        }

        @include wide-desktop {
            display: flex;
        }
    }
    &-InfoBlock,
    &-ReturnAddress {
        padding: 32px;
        border-radius: 7px;
        border: 1px solid $grey5;
        @include mobile {
            padding: 20px;
        }
    }

    &-InfoBlock {
        @include mobile {
            margin-bottom: 24px;
        }
        @include from-tablet {
            width: 65%;
            max-width: 675px;
        }
        @include desktop {
            margin-bottom: 24px;
            width: 100%;
            max-width: 100%;
        }

        @include wide-desktop {
            width: 65%;
            max-width: 675px;
            margin-bottom: 0;
        }

        h3 {
            font-family: $font-primary;
            color: $grey-dark;
            font-weight: bold;
            font-size: 15px;
            line-height: 140%;
            letter-spacing: 0.07em;
            margin-top: 0;
        }
        h4 {
            color: $default-primary-base-color;
            font-size: 15px;
            line-height: 140%;
            letter-spacing: 0.07em;
            font-family: $font-primary;
            font-weight: bold;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            &:last-child {
                margin-bottom: 0;
            }
        }

        span {
            &:nth-child(1) {
                min-width: 70px;
                display: inline-block;
                @include from-tablet {
                    min-width: 200px;
                }
            }
            &:nth-child(2) {
                font-size: 15px;
                line-height: 18px;
                letter-spacing: 0.05em;
                color: $black;
                font-family: $font-secondary;
                font-weight: normal;
            }
        }
    }
    &-InfoBlockOrders {
        min-width: 150px;

        @include from-tablet {
            min-width: 200px;
        }
        span {
            &:nth-child(1) {
                color: $default-secondary-base-color;
            }

            &:nth-child(1),
            &:nth-child(2) {
                min-width: unset;
                margin-right: 15px;
                padding-right: 15px;

                @include from-tablet {
                    min-width: unset;
                }

                &:after {
                    content: '';
                    width: 1px;
                    height: 13px;
                    position: absolute;
                    top: 4px;
                    right: 0;
                    background: $grey6;
                }
            }
        }
    }
    &-InfoBlockStatus {
        color: $white;
        font-family: $font-primary;
        font-weight: bold;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 1px;
        padding: 6px 8px;
        border-radius: var(--rma-row-status-radius);
        background: var(--rma-row-status-bg-default);
        margin-left: 10px;
        &_type_1 {
            background: var(--rma-status-bg-pending);
        }
        &_type_2 {
            background: var(--rma-status-bg-approved);
        }
        &_type_3 {
            background: var(--rma-status-bg-rejected);
        }
        &_type_4 {
            background: var(--rma-status-bg-package-sent);
        }
        &_type_5 {
            background: var(--rma-row-status-bg-default);
        }
    }
    &-ReturnAddress {
        flex: 1;
        h3 {
            font-family: $font-primary;
            color: $default-primary-base-color;
            font-weight: bold;
            font-size: 15px;
            line-height: 140%;
            letter-spacing: 0.07em;
            margin-top: 0;
            margin-bottom: 15px;
        }
        p {
            margin: 0;
            font-family: $font-secondary;
            font-weight: normal;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.05em;
            margin-top: 5px;
        }
    }
    &-Step {
        &,
        > a,
        button {
            display: flex;
            align-items: center;
        }
        &:after {
            content: '';
            height: 2px;
            background: var(--checkout-step-separator-background);
            display: block;

            @include desktop {
                margin-right: 0;
                width: 0;
            }

            @include tablet {
                width: 14px;
                margin-right: 16px;
            }

            @include mobile {
                margin-right: 8px;
                width: 54px;
            }

            @include wide-desktop {
                width: 5vw;
                max-width: 80px;
                margin-right: 24px;
            }
        }

        &:last-of-type {
            &:after {
                display: none;
            }
        }

        &_active {
            .RmaView-Step {
                &Count {
                    background: var(--checkout-step-active-count-background);
                }

                &Title {
                    color: var(--checkout-step-active-title-color);
                }
            }
        }

        &Count {
            counter-increment: checkoutSteps;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: var(--checkout-step-count-background);

            @include desktop {
                margin-right: 24px;
            }

            @include from-tablet {
                width: 32px;
                height: 32px;
                min-width: 32px;
            }

            @include tablet {
                margin-right: 16px;
            }

            @include mobile {
                width: 24px;
                height: 24px;
                min-width: 24px;
                margin-right: 8px;
            }

            &:after {
                content: counter(checkoutSteps);
                color: var(--checkout-step-count-color);
                font-family: var(--checkout-step-font);
                font-weight: 700;

                @include from-tablet {
                    font-size: 15px;
                }

                @include mobile {
                    font-size: 12px;
                }
            }
        }

        &Title {
            font-size: 15px;
            font-family: var(--checkout-step-font);
            font-weight: 700;
            color: var(--checkout-step-title-color);
            margin-right: 24px;
            cursor: default;
        }
    }
    &-TableHeading {
        color: $default-primary-base-color;
        font-size: 15px;
        line-height: 140%;
        letter-spacing: 0.07em;
        font-family: $font-primary;
        font-weight: bold;
        margin-bottom: 15px;
        margin-top: 50px;
    }
    &-Table {
        overflow-x: auto;
        display: block;
        width: 100%;
        max-width: calc(100vw - (var(--my-account-wrapper-margin-mobile) * 2));
        @include tablet {
            width: 728px;
        }
        @include desktop {
            width: 700px;
        }
        @include wide-desktop {
            width: 100%;
            display: table;
        }

        thead th {
            border-radius: 0;
            font-size: 14px;
            &:first-of-type {
                border-radius: var(--table-head-radius) 0 0 var(--table-head-radius);
            }
            &:last-of-type {
                border-radius: 0 var(--table-head-radius) var(--table-head-radius) 0;
            }
        }

        td {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        thead tr th {
            &:nth-of-type(1) {
                width: 175px;
            }
            &:nth-of-type(2) {
                width: 185px;
            }
            &:nth-of-type(4) {
                width: 85px;
            }
        }
    }
    &-TableRow {
        span {
            font-family: $font-secondary;
            font-weight: normal;
            font-size: 13px;
            line-height: 150%;
            color: $grey3;
        }
    }
    &-ProductName {
        span {
            font-family: $font-primary;
            font-weight: bold;
            font-size: 12px;
            line-height: 150%;
            letter-spacing: 0.05em;
            color: $default-primary-base-color;
        }
    }

    &-SolutionBadge {
        span {
            color: $white;
            font-family: $font-primary;
            font-weight: bold;
            font-size: 10px;
            line-height: 15px;
            letter-spacing: 1px;
            padding: 5px 8px;
            border-radius: var(--rma-row-status-radius);
            background: var(--rma-row-status-bg-default);
        }
    }

    button.RmaView-BackButton {
        --button-height: 55px;
        --button-hover-height: 55px;
    }

    &-BackButton {
        @include tablet {
            margin-bottom: 20px;
        }
    }
}
