@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --seo-text-heading-color: #{$black};
    --seo-text-content-color: #{$grey3};
}

.SeoText {
    &-ShowMore {
        a {
            display: inline-block;
            border-bottom: 1px solid $grey5;
            padding-bottom: 3px;
            margin-top: 18px;
            color: $black;
            font-weight: bold;
            font-size: 13px;
            line-height: 17px;
            text-transform: uppercase;
            &::after {
                content: '\2023';
                position: absolute;
                margin-left: 10px;
                font-size: 15px;
                transform: rotate(-90deg);
            }
        }
        > div > span:first-child > span:nth-child(2) > span {
            display: inline-flex;
            font-size: 0;
            width: 100%;
        }
        > div > span:first-child a {
            bottom: -10px;
            &::after {
                content: '\2023';
                position: absolute;
                margin-left: 10px;
                font-size: 15px;
                transform: rotate(90deg);
            }
        }
        p,
        span {
            color: $grey3;
            font-size: 16px;
            line-height: 24px;
        }
    }
}
