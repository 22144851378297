:root {
    --footer-header-color: #{$black};
    --footer-column-link-color: #{$black};
    --footer-contact-color: #{$grey3};
    --footer-contact-icon-color: #{$blue50};
    --footer-social-icon-background: transparent;
}

.Footer {
    h5 {
        font-size: 16px;
    }
    .Accordion .Accordion-Text a {
        opacity: 1;
        font-size: 13px;
        line-height: 32px;
    }
    &-Socials {
        ul li {
            @include from-tablet {
                margin-right: 10%;
            }
        }
    }

    &-Socials-Icon svg,
    &-Socials-Icon svg path {
        fill: $default-primary-base-color;
    }

    &-Socials-Icon svg {
        width: 20px;
        max-height: 20px;
    }
    &-Contact ul {
        li:first-of-type > div {
            opacity: 1;
        }
        li:first-of-type p a {
            color: $default-primary-base-color;
            font-size: 14px;
            font-weight: bold;
        }
        li:last-of-type p a {
            text-decoration: underline;
            color: $black;
        }
    }
}
