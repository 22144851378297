@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --header-icon-title-font-size: 11px;
    --header-icon-title-font-weight: 400;
    --header-icon-title-font-line-height: 13px;
    --header-icon-title-color: #{$black};
    --header-icon-margin-left: 35px;
    --header-icon-border-color: #{$orange5};
    --header-mobile-icon-margin-left: 25px;
    --header-total-height: calc(
        var(--header-height) + var(--offline-notice-height) + var(--demo-notice-height) + env(safe-area-inset-top) - 4px
    );
    --mobile-menu-button-title-font-family: #{$font-primary};
    --header-item-box-shadow-color: rgba(0, 0, 0, 0.15);
    --header-item-active-border-color: #{$default-secondary-base-color};
    --header-overlay-background: #{$black};
    --header-overlay-opacity: 0.4;
    --header-button-active-border-radius: 10px;
}

@mixin headerIconBorder {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 2px;
    height: 43px;
    background: var(--header-icon-border-color);
}

[dir='ltr'] .Header {
    &_isCheckout {
        .Header {
            &-LogoWrapper {
                margin-right: auto;
            }

            &-MyAccount {
                height: auto;
                margin-left: 0;

                @include mobileAndTablet {
                    position: static;
                }
            }
        }
    }

    &-Nav {
        padding-left: var(--content-padding);
        padding-right: var(--content-padding);
        @include desktop {
            --header-nav-height: 68px;
        }
    }

    &-LogoWrapper {
        opacity: 1;
        max-width: none;
        --header-logo-height: 57px;
        --header-logo-width: 200px;

        @include mobileAndTablet {
            margin-left: 0;
            margin-right: auto;
        }

        @include mobile {
            --header-logo-height: 36px;
            --header-logo-width: 130px;
        }
    }

    &-Button {
        @include button-visible;
        width: auto;
    }

    &-Button {
        &_type {
            &_minicart {
                @include desktop {
                    width: auto;
                    height: auto;
                    margin-left: 0;
                }
            }
            &_menu {
                margin-left: 26px;
                margin-top: 0;
                box-shadow: none;
                @include mobileAndTablet {
                    @include button-visible;
                    width: auto;
                    height: 40px;
                    padding: 0 25px;
                    background: $default-primary-base-color;

                    .MobileMenu {
                        &-Title {
                            color: $white;
                            margin: 0;
                            font-size: 15px;
                            font-family: var(--mobile-menu-button-title-font-family);
                        }

                        &-Icon {
                            width: 16px;
                            margin-left: 15px;
                        }
                    }
                }

                @include mobile {
                    height: 40px;
                    padding: 0 10px;

                    .MobileMenu {
                        &-Title {
                            display: none;
                        }

                        &-Icon {
                            margin-left: 0;
                        }
                    }
                }
            }

            &_wishlist,
            &_compare {
                height: auto;
            }
        }
    }

    &-Help,
    &-MyAccount,
    &-WishlistButton,
    &-CompareButton,
    &-MinicartButton {
        &Wrapper {
            width: auto;

            @include desktop {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                min-height: 45px;
            }

            @include tablet {
                margin-left: 45px;
            }

            @include mobile {
                margin-left: 20px;
            }

            &_isActive {
                @include desktop {
                    position: relative;
                    z-index: 1000;

                    &:before {
                        content: '';
                        width: calc(100% + 32px);
                        height: calc(100% + 20px);
                        top: -10px;
                        left: -16px;
                        position: absolute;
                        border-radius: var(--header-button-active-border-radius)
                            var(--header-button-active-border-radius) 0 0;
                        border: 1px solid var(--header-item-active-border-color);
                        border-bottom: none;
                        background: $white;
                        box-shadow: 0 -1px 15px -1px var(--header-item-box-shadow-color);
                    }

                    &:after {
                        content: '';
                        width: calc(100% + 32px);
                        height: 20px;
                        top: 100%;
                        left: -15px;
                        position: absolute;
                        background: $white;
                    }
                }
            }
        }

        &Title {
            @include desktop {
                font-size: var(--header-icon-title-font-size);
                font-weight: var(--header-icon-title-font-weight);
                line-height: var(--header-icon-title-font-line-height);
                color: var(--header-icon-title-color);
                white-space: nowrap;
            }
        }
    }

    &-Help,
    &-MyAccountWrapper,
    &-MinicartButtonWrapper,
    &-CompareButtonWrapper,
    &-WishlistButtonWrapper {
        @include desktop {
            margin-left: 30px;
        }
    }

    &-MyAccount {
        .MyAccountOverlay_isVisible .Loader {
            @include desktop {
                border-radius: 10px;
            }
        }
        &Icon {
            margin: auto;

            @include desktop {
                width: 18px;
                height: 21px;
            }

            @include tablet {
                width: 20px;
                height: 25px;
            }

            @include mobile {
                width: 18px;
                height: 20px;
            }
        }
    }
    &-Minicart {
        &Button {
            &Wrapper {
                height: auto;

                @include desktop {
                    height: 45px;
                }
            }

            &Icon {
                margin: auto;
                @include desktop {
                    width: 27px;
                    height: 23px;
                }

                @include tablet {
                    width: 28px;
                    height: 28px;
                }

                @include mobile {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        &ItemCount {
            @include from-tablet {
                top: -7px;
                right: -11px;
            }
            @include desktop {
                right: -7px;
            }
        }
    }

    &-Help {
        @include desktop {
            padding-left: 30px;
            position: relative;

            &:before {
                @include headerIconBorder;
            }
        }

        &Icon {
            margin: auto;
            @include desktop {
                width: 20px;
                height: 20px;
            }

            @include tablet {
                width: 22px;
                height: 22px;
            }

            @include mobile {
                width: 18px;
                height: 18px;
            }
        }
    }

    &-CompareButtonWrapper,
    &-WishlistButtonWrapper {
        .Header-Icon {
            margin: auto;
            @include desktop {
                width: 22px;
                height: 22px;
            }
        }
    }

    &-ItemsCount {
        background: var(--primary-base-color);
        border-radius: 50%;
        color: var(--color-white);
        height: 17px;
        padding: 2px;
        position: absolute;
        width: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 80%;
        font-weight: 700;

        @include desktop {
            right: 6px;
            top: -6px;
        }
    }

    &-CompareButtonWrapper {
        .Header-ItemsCount {
            @include desktop {
                right: 2px;
            }
        }
    }

    &-Overlay {
        @include desktop {
            position: absolute;
            top: 100%;
            left: 50%;
            width: 100vw;
            transform: translateX(-50%);
            height: 100vh;
            background: var(--header-overlay-background);
            opacity: var(--header-overlay-opacity);
            max-width: none;
        }
    }

    .StoreSwitcher,
    .CurrencySwitcher {
        @include desktop {
            padding-left: 30px;
            position: relative;

            &:before {
                @include headerIconBorder;
            }
        }
    }
}
