.homepage-banner-bargain {
    @include from-tablet {
        display: flex;
        max-height: 200px;
    }
    @include desktop {
        max-height: 376px;
    }
    img {
        @include mobile {
            position: static;
            transform: none;
            display: block;
            height: 250px;
            width: 100%;
        }
        @include desktop {
            object-fit: cover;
            object-position: center;
            width: 100%;
            overflow: hidden;
        }
    }
    &-box {
        background: $default-primary-base-color;
        @include mobile {
            padding: 25px 0 30px;
        }
        @include from-tablet {
            position: static;
            transform: none;
            order: -1;
            min-width: 276px;
            padding: 40px;
        }
        @include desktop {
            min-width: 536px;
            padding: 67px;
        }
        p {
            text-align: left;
            font-weight: bold;
            font-size: 32px;
            line-height: 40px;
            width: 100%;
            letter-spacing: unset;
            @include mobile {
                padding: 0 var(--content-padding);
            }
            @include from-tablet {
                font-size: 22px;
                line-height: 32px;
            }
            @include desktop {
                font-size: 56px;
                line-height: 64px;
            }
            a {
                background: transparent;
                border: 1px solid $white;
                display: inline-block;
                text-align: center;

                &.Button {
                    --button-height: 48px;
                    --button-hover-height: 48px;

                    font-weight: bold;
                    font-size: 13px;
                    line-height: 17px;
                    display: flex;
                    align-items: center;
                }
                @include mobile {
                    width: 100%;
                }
                @include from-tablet {
                    max-width: 145px;
                }
            }
        }
    }
}
