@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.Menu {
    --menu-border-radius: 0;
    --menu-item-caption-parent-hover-color: #{$blue50};
    --menu-item-caption-special-color: #{$blue50};
    --subcategory-item-active-background: #{$blue5};
    --subcategory-column-border-color: #{$blue5};
    --subcategory-border-color: #{$blue50};

    &-Subcategories {
        .Menu-ItemCaption {
            @include desktop {
                border-radius: 0;
            }
        }
    }
    &-AdditionalInformation {
        .Accordion {
            &-Header {
                font-size: 11px;
                color: $grey-dark;
                height: 100%;

                > div {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    svg {
                        margin-right: 20px;
                    }
                }

                &:after {
                    top: 14px;
                }
            }

            &-Box {
                padding: 20px 38px 20px 30px;

                @include mobileAndTablet {
                    padding: 10px 38px 10px 30px;
                    border: none;
                    height: 50px;
                }

                &_isOpen {
                    height: 100%;

                    .Accordion-Header {
                        &:after {
                            top: 7px;
                        }
                    }
                }
            }
        }
    }

    &-CompareLink {
        span {
            font-size: 11px;
            color: $grey-dark;
            font-weight: 400;
        }

        .Menu-Icon {
            margin-right: 20px;
            width: 18px;
            height: 18px;
        }
    }

    &-MenuWrapper {
        display: flex;
        justify-content: space-between;
        position: relative;

        @include mobileAndTablet {
            flex-direction: column;
            justify-content: space-between;
        }

        .AccountManager {
            @include mobileAndTablet {
                padding: 0;
            }

            &-Component {
                @include mobileAndTablet {
                    position: static;
                }

                @include tablet {
                    width: var(--menu-tablet-width);
                }

                @include mobile {
                    width: var(--menu-mobile-width);
                }

                .Accordion {
                    &-Box {
                        @include mobileAndTablet {
                            padding: 10px 38px 10px 30px;
                            height: 50px;
                            border: none;

                            &_isOpen {
                                height: 100%;
                            }
                        }

                        .Accordion-Text {
                            a {
                                font-size: 13px;
                                line-height: 140%;
                                font-weight: normal;
                                color: $black;
                                margin: 3px 0;
                                opacity: 1;
                            }

                            .MyAccountManagerItem-Email {
                                color: var(--link-color);
                            }
                        }
                    }
                }
            }
        }
    }

    &-MainCategories {
        @include desktop {
            transform: translateX(-25px);
            z-index: 1;
            width: calc(100% - 150px);
        }

        @include mobileAndTablet {
            order: 2;
        }
    }

    &-Item {
        @include mobileAndTablet {
            border-bottom: 1px solid $grey5;
            padding: 5px;

            &:not(:first-of-type) {
                margin-inline-start: 0;
            }
        }

        &Caption {
            --menu-item-caption-color: #{$black};
        }
    }
}
