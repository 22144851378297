@import '../../style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../style/abstract/button';

.Field {
    [type='checkbox'] {
        + .input-control {
            &:hover {
                @include desktop {
                    &::after {
                        --checkmark-color: #{$default-secondary-base-color};
                    }
                }
            }
        }
        &:checked {
            + div.input-control {
                border-color: #{$default-secondary-base-color};
                background-color: $default-secondary-base-color;
            }
        }
    }
}
