:root {
    --tiles-background-color: #{$grey-light};
    --tiles-content-header-color: #{$default-primary-base-color};
    --tiles-content-border-radius: 10px;
    --tiles-content-background-color: #{$white};
}

.homepage-tiles {
    margin: 50px auto 130px;
    position: static;
    &:after {
        content: '';
        position: absolute;
        top: 20%;
        left: 50vw;
        transform: translateX(-50%);
        height: calc(80% + 50px);
        z-index: -1;
        width: 100vw;
        background: var(--tiles-background-color);
    }

    &::before {
        @include from-tablet {
            content: '';
            background: url('../../../assets/svg/circles.svg') no-repeat;
            width: 690px;
            height: 316px;
            background-size: 100%;
            position: absolute;
            top: -20%;
            left: 0;
            z-index: -1;
        }

        @include desktop {
            transform: translateX(-35%) rotate(45deg);
        }

        @include tablet {
            transform: translateX(-70%) rotate(45deg);
        }
    }

    @include desktop {
        max-width: calc(var(--content-wrapper-width));
        padding-left: var(--content-padding);
        padding-right: var(--content-padding);
        overflow: hidden;
    }

    @include tablet {
        max-width: calc(100% - 20px);
        padding: 0 10px;
    }

    @include mobile {
        max-width: calc(100% - 20px);
        padding: 0 10px;
    }

    img {
        max-height: 285px;
    }

    a {
        &:hover {
            figcaption:after {
                margin-left: 15px;
            }
        }
    }

    figure {
        border-radius: var(--tiles-content-border-radius);
        overflow: hidden;
    }

    figcaption {
        background: var(--tiles-content-background-color);
        transform: translateY(-5px);
        border-radius: var(--tiles-content-border-radius);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 10px 70px;
        position: relative;

        &::after {
            position: absolute;
            transition: margin 0.5s ease;
            bottom: 30px;
            content: url('../../../assets/svg/full-arrow-right.svg');
        }
    }

    h5 {
        font-size: 22px;
        line-height: 30px;
        color: var(--tiles-content-header-color);
        margin-bottom: 15px;
    }

    p {
        max-width: 240px;
        text-align: center;
        margin-bottom: 0;
        color: $grey-dark;
    }

    .AdvoxSlider {
        margin: 0 auto;
        padding: 0;
        --slider-height-tablet: 500px;

        @include desktop {
            .slick-list {
                margin: 0 -2vw;
            }
        }
        @include widest-desktop {
            .slick-list {
                margin: 0 -2.2vw;
            }
        }
        @include tablet {
            .slick-slide {
                padding: 0 3vw;
            }

            .slick-list {
                margin: 0 -3vw;
            }
        }

        .slick-slide {
            @include mobile {
                & > div {
                    max-width: 324px;
                }
            }

            > div {
                margin: 0 auto;
                max-width: 280px;

                @include tablet {
                    max-width: 398px;
                }

                @include wide-desktop {
                    max-width: 398px;
                    margin: 0 auto;
                }
            }
        }

        .slick-arrow {
            @include mobile {
                top: 50%;
            }
        }
    }
}
