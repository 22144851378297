:root {
    --tiles-background-color: #{$grey10};
    --tiles-content-header-color: #{$black};
    --tiles-content-border-radius: 0;
}

.homepage-tiles {
    &:before {
        display: none;
    }
    .AdvoxSlider {
        .slick-arrow {
            @include mobile {
                display: block !important;
                border-radius: 20px 0 0 20px;
                width: 40px;
                height: 40px;
            }

            &.slick-prev {
                @include mobile {
                    border-radius: 0 20px 20px 0;
                }
                @include from-tablet {
                    left: 0;
                }
                svg {
                    @include mobile {
                        right: -15px;
                    }
                }
            }
            &.slick-next {
                @include mobile {
                    border-radius: 20px 0 0 20px;
                }

                @include from-tablet {
                    right: 0;
                }

                svg {
                    @include mobile {
                        left: 20px;
                    }
                }
            }
        }
        .slick-dots {
            width: 100%;
            bottom: -15px;
            li {
                display: inline-block;
            }
        }
    }

    figcaption {
        align-items: flex-start;
        padding: 30px 33px 25px;
        @include from-tablet {
            padding: 30px 48px 25px;
        }
        &::after {
            display: none;
        }
        h5,
        p {
            text-align: left;
            width: 100%;
            max-width: 250px;
        }
        p {
            font-size: 16px;
            line-height: 24px;
            color: $grey3;
        }
        span {
            text-transform: uppercase;
            margin-top: 22px;
            display: inline-block;
            border-bottom: 1px solid $grey5;
            padding-bottom: 3px;
            margin-top: 18px;
            color: $black;
            font-weight: bold;
            font-size: 13px;
            line-height: 17px;
            &::after {
                content: '\2023';
                position: absolute;
                margin-left: 10px;
                font-size: 15px;
            }
        }
    }
}
