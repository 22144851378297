@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --debt-background: #{$red50};
    --debt-color: #fff;
}

.Debt {
    display: flex;
    align-items: center;
    width: 100%;
    padding-inline: 20px 60px;
    padding-block: 25px 20px;
    background-color: var(--debt-background);

    @include desktop {
        text-align: center;
        justify-content: center;
        padding-block-start: 30px;
    }
}

.Debt-Message {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: var(--debt-color);
}

.Debt-Icon {
    path {
        fill: var(--debt-color);
    }

    &_info {
        height: 20px;
        width: 20px;
        min-width: 20px;
        margin-inline-end: 15px;
    }

    &_close {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-end: 20px;
        transform: translateY(-50%);

        @include desktop {
            transform: translateY(calc(-50% + 4px));
        }
    }
}
