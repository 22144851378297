h1 {
    font-size: 56px;
    line-height: 64px;
    letter-spacing: 0;
    margin-bottom: 48px;
}

h2 {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0;
    margin-bottom: 40px;
}

h3 {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0;
    margin-bottom: 32px;
}

h4 {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 0;
    margin-bottom: 24px;
}

h5 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    margin-bottom: 20px;
}

h6 {
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0;
    margin-bottom: 17px;
}
