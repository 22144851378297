@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.ContractorBlockedPopup {
    &-Wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-Heading {
        font-size: 24px;
        font-weight: 700;
        line-height: 100%;
        color: $red50;
        margin-bottom: 20px;
        text-align: center;
    }

    &-Message {
        font-size: 16px;
        line-height: 150%;
        font-weight: 400;
        text-align: center;
    }

    .Icon-Padlock {
        width: 54px;
        height: 72px;
        transform: translateY(-20px);
    }
}
