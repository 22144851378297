@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --reset-attributes-value-background: #{$white};
    --reset-attributes-value-border-radius: 3px;
    --reset-attributes-label-color: #{$grey-dark};
    --reset-attributes-border-color: #{$blue5};
}

.ResetAttributes {
    &-AttributeValue {
        border: solid 1px var(--reset-attributes-border-color);
    }

    &-AttributeLabel {
        margin-right: 6px;
    }

    &-AttributeText {
        font-weight: normal;
    }
}
