:root {
    --footer-header-color: #{$black};
    --footer-column-link-color: #{$black};
    --footer-contact-color: #{$grey3};
    --footer-contact-icon-color: #{$blue50};
    --footer-social-icon-background: transparent;
}

.Footer {
    &-Socials-Icon svg,
    &-Socials-Icon svg path {
        fill: $default-secondary-base-color;
    }
    &-Contact {
        &-Icon path {
            fill: var(--footer-contact-icon-color);
        }
        &-Icon {
            fill: unset;
            stroke: unset;
        }
        ul {
            li:first-of-type {
                .Footer-Contact-Icon {
                    stroke: var(--footer-contact-icon-color);
                }
                p a {
                    color: $default-secondary-base-color;
                    font-size: 14px;
                }
            }
        }
    }
}
