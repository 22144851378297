:root {
    --paragraph-color-default: #5e6875;
    --paragraph-font-size-s: 13px;
    --paragraph-font-size-m: 15px;
    --paragraph-font-size-l: 18px;
}

.Paragraph {
    color: var(--paragraph-color-default);
    line-height: 150%;
    font-size: var(--paragraph-font-size-m);
    margin: 0;
    letter-spacing: 0.05em;

    &_size {
        &_s {
            font-size: var(--paragraph-font-size-s);
        }

        &_m {
            font-size: var(--paragraph-font-size-m);
        }

        &_l {
            font-size: var(--paragraph-font-size-l);
        }
    }
}
