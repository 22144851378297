@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --store-switcher-label-color: #{$blue80};
    --store-switcher-button-color: #{$blue80};
    --store-switcher-line-background: #{$orange10};
    --store-switcher-color: #{$default-primary-base-color};
}

[dir='ltr'] .StoreSwitcher {
    border-bottom: none;

    &-Title {
        color: var(--store-switcher-color);

        @include desktop {
            --store-switcher-color: #{$default-secondary-base-color};
        }
    }

    .FieldSelect {
        &-Select {
            font-weight: 700;
            color: var(--store-switcher-color);
            border: 1px solid var(--input-border-color);

            &_isExpanded {
                border-bottom: 0;
            }
        }
    }

    .ChevronIcon {
        fill: var(--store-switcher-color);

        @include desktop {
            --input-color: #{$default-secondary-base-color};
        }
    }

    &-StoreListWithoutSelectLabel {
        font-size: 14px;
        line-height: 34px;
        font-family: $font-secondary;
        letter-spacing: -0.2px;
        color: var(--store-switcher-label-color);
        opacity: 0.4;
    }

    &-StoreListWithoutSelectContainer {
        margin-left: 18px;
        padding-left: 18px;
        position: relative;

        &:before {
            content: '';
            width: 2px;
            height: 100%;
            background: var(--store-switcher-line-background);
            border-radius: 100px;
            position: absolute;
            left: 0;
        }
    }

    &-StoreListWithoutSelectButton {
        margin-right: 25px;
        color: var(--store-switcher-button-color);
        opacity: 0.4;
        cursor: pointer;
        font-size: 14px;

        &:last-of-type {
            margin-right: 0;
        }

        &_current {
            cursor: default;
            opacity: 1;
        }
    }

    &-StoreListWithoutSelect {
        display: flex;
        align-items: center;
    }
}
