@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.UniqueSellingProposition {
    > div {
        width: 100%;
    }
    svg {
        cursor: default;
    }
    .usp__heading {
        font-family: $font-primary;
        font-weight: bold;
        font-size: 15px;
        line-height: 140%;
        letter-spacing: 0.07em;
        color: $blue50;
        margin: 10px 0 7px;
    }

    .usp__text {
        font-family: $font-secondary;
        font-weight: normal;
        font-size: 15px;
        line-height: 150%;
        color: $grey3;
        margin-bottom: 40px;
    }
}
