@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.ContractorStructureRestrictingBuy {
    &-Content {
        overflow-x: hidden;

        @include desktop {
            --popup-min-width: 816px;
            border: 0;
        }

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--popup-scroll-accent-color);
            border-right: 0;
            margin-top: 0;
            margin-bottom: 0;
        }

        &::-webkit-scrollbar-track {
            background-color: $grey5;
            border-right: 0;
            margin-top: 0;
            margin-bottom: 0;
        }

        .Popup-Header {
            z-index: 1;
            overflow: hidden;
            padding-inline-end: 64px;
            padding-block: 35px 25px;
            margin-block-end: 25px;

            @include desktop {
                padding-inline: 35px;
                margin-inline: -35px;
                margin-block-start: -35px;
            }

            &::after {
                content: '';
                background-color: $grey20;
                position: absolute;
                inset: 0;
                z-index: -1;
                pointer-events: none;
            }

            .Popup-CloseBtn {
                top: 8px;
                right: 8px;

                @include mobileAndTablet {
                    position: absolute;
                }
            }
        }
    }

    &-Heading {
        margin: 0;

        @include mobile {
            line-height: 24px;
        }
    }

    &-Actions {
        display: grid;
        align-items: center;
        grid-gap: 16px;
        margin-block-start: 24px;

        @include desktop {
            grid-template-columns: repeat(2, auto);
            grid-gap: 45px;
        }

        .ContractorStructureRestrictingBuy-Fieldset {
            align-items: center;
        }

        .ContractorStructureRestrictingBuy-Label {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
        }
    }

    &-File {
        margin-block-start: 0;

        .Field-Label {
            --button-height: 40px;
            --button-hover-height: 40px;

            @include button;

            padding-block-end: 0;
        }
    }

    &-Download {
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        line-height: 18px;
        cursor: pointer;

        .Icon-Download {
            height: 20px;
            width: 18px;
            margin-inline-end: 12px;
        }
    }

    &-Sku {
        display: flex;
        flex-direction: column;
    }

    &-Fieldset {
        display: grid;
        grid-gap: 16px;

        @include from-tablet {
            grid-template-columns: auto 1fr;
            grid-gap: 24px;
        }
    }

    &-Label {
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        margin-block: 0;
    }

    &-Input {
        width: 100%;
    }

    &-Textarea {
        margin-block-start: 0;

        textarea {
            padding-block: 8px;
            padding-inline: 16px;
            width: 100%;
        }
    }

    &-Message {
        display: block;
        margin-block-start: 8px;
        font-size: 12px;
        line-height: 16px;
    }

    &-Add {
        --popup-botton-background: #{$green50};
        --button-height: 40px;
        --button-hover-height: 40px;

        @include from-tablet {
            margin-inline-start: auto;
            width: 230px !important;
        }
    }

    &-Products {
        margin-block: 18px 28px;

        .ContractorStructureRestrictingBuy-Label {
            margin-block-end: 12px;
        }
    }

    &-Table {
        table-layout: auto;
        width: 100%;

        th,
        td {
            vertical-align: middle;

            &:last-of-type:not([colSpan='3']) {
                text-align: right;
            }
        }

        .Icon-Remove {
            height: 13px;
            width: 13px;
        }
    }

    &-Search {
        display: grid;
        grid-template-columns: 30px minmax(150px, 1fr);
        grid-gap: 20px;
        align-items: center;

        .Field {
            --input-padding: 6px 8px 6px 32px;

            margin-block-start: 0;
            width: auto;

            input::placeholder {
                color: $grey3;
            }
        }

        .SearchIcon {
            left: 8px;
            right: inherit;
            fill: $blue50;
        }
    }

    &-Footer {
        display: flex;

        @include from-tablet {
            align-items: center;
            justify-content: space-between;
        }

        @include mobile {
            flex-direction: column;
        }

        .MyAccountPagination {
            min-height: inherit;

            @include from-tablet {
                margin: 0;
            }
        }
    }

    &-Remove {
        --popup-botton-background: #{$error-color};
        --button-height: 40px;
        --button-hover-height: 40px;

        @include from-tablet {
            margin-block-start: 0 !important;
            width: 230px !important;
        }
    }
}
