@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.Accordion {
    &-Header {
        margin: auto 0;

        &:after {
            width: 0;
            height: 0;
            border-width: 0 2px 2px 0;
            border-top: 4px solid $default-primary-base-color;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 4px solid transparent;
            content: '';
            position: absolute;
            top: 10px;
            right: 0;
            transform: none;
        }
    }

    &-Box {
        &_isOpen {
            .Accordion-Header {
                &:after {
                    transform: rotate(-180deg);
                }
            }
        }
    }
}
