@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.FreeDeliveryIndicator {
    align-items: center;
    border: 1px solid $blue50;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    gap: 16px;
    margin-bottom: 24px;
    padding: 10px 22px;
    width: fit-content;

    @include mobile {
        font-size: 12px;
        justify-content: center;
        padding: 8px 12px;
        width: 100%;

        svg {
            height: 20px;
            width: 20px;
        }
    }

    &_isCategoryPage {
        justify-content: center;
        width: 100%;

        @include mobile {
            margin: 16px;
            width: auto;
        }
    }

    svg {
        &:nth-of-type(2) {
            path {
                fill: var(--secondary-base-color)
            }
        }
    }
}