.homepage-banner-bargain {
    &-box {
        background: $default-secondary-base-color;

        p {
            a {
                &.Button {
                    --button-radius: 5px;
                    --button-hover-radius: 5px;
                    --button-hover-border: #{$default-secondary-dark-color};
                    --button-hover-background: #{$default-secondary-dark-color};
                }
            }
        }
    }
}
