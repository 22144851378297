@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --password-weak-color: #{$red-light2};
    --password-good-color: #{$default-secondary-base-color};
    --password-strong-color: #{$default-tertiary-base-color};
}

.PasswordStrength {
    &-Label {
        display: block;
        margin-top: 5px;
        font-size: 13px;
        color: $grey-dark;
        font-family: $font-secondary;
        line-height: 150%;

        &_type_weak > span {
            color: var(--password-weak-color);
        }

        &_type_good > span {
            color: var(--password-good-color);
        }

        &_type_strong > span {
            color: var(--password-strong-color);
        }
    }

    &-Progress {
        -webkit-appearance: none;
        -moz-appearance: none;
        border: 1px solid $grey;
        border-radius: 100px;
        height: 8px;
        width: 100%;

        &_type_weak {
            &,
            &::-moz-progress-bar {
                background-color: var(--password-weak-color);
                border-color: var(--password-weak-color);
            }

            &[value] {
                border-color: var(--password-weak-color);

                &::-webkit-progress-bar,
                &::-webkit-progress-value {
                    background-color: var(--password-weak-color);
                }
            }
        }

        &_type_good {
            &,
            &::-moz-progress-bar {
                background-color: var(--password-good-color);
                border-color: var(--password-good-color);
            }

            &[value] {
                border-color: var(--password-good-color);

                &::-webkit-progress-bar,
                &::-webkit-progress-value {
                    background-color: var(--password-good-color);
                }
            }
        }

        &_type_strong {
            &,
            &::-moz-progress-bar {
                background-color: var(--password-strong-color);
                border-color: var(--password-strong-color);
            }

            &[value] {
                border-color: var(--password-strong-color);

                &::-webkit-progress-bar,
                &::-webkit-progress-value {
                    background-color: var(--password-strong-color);
                }
            }
        }
    }
}
