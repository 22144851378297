@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.OrderRequestDetailPopup {
    &-Content {
        max-width: 810px;
        overflow-x: hidden;

        @include mobileAndTablet {
            .Popup-Header {
                padding-block-start: 70px;
            }
        }

        @media print {
            .Popup-Header {
                padding-block-start: 35px;
            }
        }

        .Popup-CloseBtn {
            inset-block-start: -27px !important;
            inset-inline-end: -27px !important;

            @include mobileAndTablet {
                position: absolute !important;
                inset-block-start: 12px !important;
                inset-inline-end: 12px !important;
            }

            @media print {
                display: none;
            }
        }
    }

    .Table-Wrapper:not(:last-of-type) {
        margin-block-end: 24px;
    }

    &-Header {
        width: 100%;

        &:not(&_content) {
            padding-block-end: 50px;

            &:after {
                content: '';
                position: absolute;
                inset-block: -35px 20px;
                inset-inline: -35px;
                background-color: #f3f3f3;

                @include mobileAndTablet {
                    inset-block-start: -70px;
                }
            }
        }

        &_content {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        & > * {
            z-index: 1;
        }
    }

    &-Status {
        display: inline-block;
        margin-inline-end: 16px;
        padding-block: 6px;
        padding-inline: 8px;
        background-color: $default-primary-base-color;
        color: $white;
        font-size: 13px;
        font-weight: bold;
        line-height: 17px;

        @include mobile {
            margin-bottom: 16px;
        }
    }

    &-Title {
        color: $grey3;
        font-size: 24px;
        font-weight: bold;
        line-height: 31.5px;

        & > span {
            color: $black;
        }
    }

    &-Table {
        table-layout: auto;
        width: 100%;
        margin-block-end: 0;

        td,
        th {
            vertical-align: top;
            text-align: left;
        }

        &_details {
            --table-head-background: #{$white};
            --table-head-padding: 4px 0;
            --table-body-border: none;

            td:first-of-type {
                color: $grey3;
            }
        }

        &_summary {
            td:first-of-type {
                font-weight: bold;
            }

            td:last-of-type {
                text-align: right;

                .OrderRequestDetailPopup-Price {
                    &Label {
                        font-size: 13px;
                        line-height: 16px;
                        white-space: nowrap;
                    }

                    &Value {
                        font-size: 15px;
                        line-height: 24px;
                        white-space: nowrap;
                    }
                }
            }
        }
    }

    &-Order {
        text-align: right;

        .Button {
            --popup-botton-background: #{$green50};
            margin-block-start: 0 !important;

            @include desktop {
                width: 50% !important;
            }
        }
    }

    &-Price {
        display: inline-grid;
        grid-template-columns: repeat(2, auto);
        grid-column-gap: 4px;
        align-items: baseline;

        &Label {
            color: #{$grey-dark};
            font-size: 12px;
            line-height: 20px;
            white-space: nowrap;
        }

        &Value {
            font-size: 13px;
            line-height: 17px;
            white-space: nowrap;

            &_bold {
                font-weight: bold;
            }
        }
    }

    &-Actions {
        margin-block-start: 24px;

        &_status {
            padding-inline: 16px;
            padding-block: 16px;
            width: 100%;
            background-color: $white;
            color: $grey-dark;
            font-size: 13px;
            font-weight: bold;
            line-height: 17px;
            text-align: center;
            text-transform: uppercase;
        }

        &_reject {
            color: $error-color;
        }

        &_approve {
            color: $green50;
        }

        &_processed {
            color: $orange50;
        }
    }

    &-Action {
        &_reject,
        &_approve,
        &_processed {
            margin-block-start: 0 !important;
        }

        &_reject {
            --popup-botton-background: #{$error-color};
        }

        &_approve {
            --popup-botton-background: #{$green50};
        }

        &_processed {
            --popup-botton-background: #{$orange50};
        }
    }

    &-Button {
        display: inline-flex;
        align-items: center;
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
        cursor: pointer;
        color: $black;
        margin-block-start: 24px;

        .Icon {
            &-Print {
                height: 16px;
                width: 16px;
                margin-right: 12px;
            }
        }
    }

    &-Product {
        display: flex;
        align-items: flex-start;
        font-size: 13px;
        line-height: 16px;

        .Image {
            width: 65px;
            padding-bottom: 65px;
            margin-inline-end: 16px;
        }
    }
}
