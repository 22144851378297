@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --newsletter-background: #{$default-primary-base-color};
    --newsletter-agreement-link-color: #{$white};
    --newsletter-subscription-placeholder-color: #{$grey3};
    --consents-button-color: #{$white};
}

.Newsletter {
    &-Container {
        @include mobile {
            padding: 20px 0 34px;
        }
    }
    &-InfoWrapper {
        @include tablet {
            width: 100%;
            max-width: 576px;
        }
    }
    &-Info {
        text-align: left;
        h2 {
            padding: 0;
        }
        p {
            color: $blue20;
        }
    }
    &-FormWrapper {
        @include mobileAndTablet {
            width: 100%;
        }
        @include tablet {
            max-width: 576px;
        }

        @include mobile {
            max-width: 360px;
        }
    }
    .NewsletterSubscription {
        @include desktop {
            max-width: 536px;
        }
        .Button {
            --button-border-radius: 0;
        }

        .FieldForm-Fieldset .FieldForm-Fields .Field_type_text {
            &::before {
                content: '';
                position: absolute;
                left: 26px;
                top: 50%;
                transform: translateY(-50%);
                width: 20px;
                height: 18px;
                content: url('../../assets/svg/mail.svg');
                z-index: 2;
            }
            input {
                padding: 14px 170px 14px 66px;

                @include mobile {
                    width: 100%;
                }
            }
        }

        .FieldForm-Fieldset .Button {
            font-size: 13px;
            line-height: 17px;
            @include mobile {
                position: static;
                width: 100%;
                border: 1px solid $white;
                margin-top: 16px;
            }
        }

        .Consents {
            .ShowMoreLessContent-Button {
                text-decoration: underline;
            }

            .Field-CheckboxLabel span {
                color: $blue20
            }
        }
    }
}
