@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --custom-price-accent-color: #eeb936;
    --custom-price-item-label-color: #a1a1a1;
    --custom-price-item-value-color: #{$black};
    --custom-price-item-background: rgba(238, 185, 54, 0.1);
    --custom-price-height: 66px;
    --custom-price-height-pdp: 80px;
    --custom-price-font-size: 15px;
    --custom-price-line-height: 20px;
    --custom-price-font-size-pdp: 23px;
    --custom-price-line-height-pdp: 27px;
    --custom-price-font-size-pdp-no-tax: 24px;
    --custom-price-line-height-pdp-no-tax: 28px;
}

.CustomPrice {
    display: flex;
    align-items: center;
    margin: 15px 0 0;
    min-width: 100%;

    @include mobile {
        display: block;
    }
    &-Label {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--custom-price-accent-color);
        min-height: var(--custom-price-height);
        padding: 16px;
        text-align: center;
        max-width: 88px;

        &::after {
            content: '\A';
            border-style: solid;
            border-width: 8px 13px 8px 0;
            border-color: transparent var(--custom-price-accent-color) transparent transparent;
            position: absolute;
            right: -10px;
            transform: rotate(180deg);

            @include mobile {
                transform: rotate(-90deg);
                bottom: -12px;
                left: 15px;
                right: unset;
                bottom: -12px;
                left: 15px;
                border-width: 8px 8px 8px 0;
            }
        }

        @include mobile {
            display: block;
            width: 100%;
            max-width: unset;
            min-height: unset;
            font-size: 12px;
            line-height: 16px;
            padding: 6px;
            text-align: left;
        }
    }

    &-Prices {
        background: var(--custom-price-item-background);
        padding: 0 16px;
        min-height: var(--custom-price-height);
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        @include mobile {
            padding: 9px;
            min-height: unset;
        }
    }

    &-PriceLabel {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: var(--custom-price-item-label-color);
        margin-right: 5px;
        min-width: 40px;
        display: inline-block;
        font-family: var(--font-roboto);
    }

    &-PriceValue {
        font-weight: normal;
        font-size: var(--custom-price-font-size);
        line-height: var(--custom-price-line-height);
        color: var(--custom-price-item-value-color);
        font-family: var(--font-roboto);

        @include mobile {
            font-size: 15px;
            line-height: 20px;
        }
    }

    &-PriceItem {
        margin: 0 10px;
        display: flex;
        align-items: baseline;
        @include mobile {
            margin: 0;
        }
        &_withoutTax {
            .CustomPrice-PriceValue {
                color: var(--custom-price-accent-color);
                font-weight: bold;
                letter-spacing: -0.2px;
            }
        }
    }
    &_isSingleProduct {
        --custom-price-height: var(--custom-price-height-pdp);
        --custom-price-font-size: var(--custom-price-font-size-pdp);
        --custom-price-line-height: var(--custom-price-line-height-pdp);
        margin: 20px 0 25px;

        @include mobile {
            width: 100%;
            display: flex;
        }

        @include tablet {
            min-width: 100%;
        }

        &,
        .CustomPrice-Prices {
            @include desktop {
                width: unset;
                min-width: unset;
            }
        }
        .CustomPrice-PriceItem {
            margin: 0 16px;
        }
        .CustomPrice-PriceItem_withoutTax .CustomPrice-PriceValue {
            --custom-price-font-size: var(--custom-price-font-size-pdp-no-tax);
            --custom-price-line-height: var(--custom-price-line-height-pdp-no-tax);
        }
        .CustomPrice-Prices {
            gap: 5px;

            @include mobile {
                gap: unset;
                min-height: 80px;
            }
        }
        .CustomPrice-Label {
            @include mobile {
                right: -10px;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                text-transform: uppercase;
                color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                background: var(--custom-price-accent-color);
                min-height: var(--custom-price-height);
                padding: 16px;
                text-align: center;
                max-width: 88px;
                margin-left: -10px;
            }

            &::after {
                @include mobile {
                    content: '\A';
                    border-style: solid;
                    border-width: 8px 10px 8px 0;
                    border-color: transparent var(--custom-price-accent-color) transparent transparent;
                    position: absolute;
                    right: -7px;
                    transform: rotate(180deg);
                    bottom: unset;
                    left: unset;
                }
            }
        }
    }
}
