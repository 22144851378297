:root {
    --footer-header-color: #{$blue50};
    --footer-column-link-color: #{$blue50};
    --footer-contact-color: #{$blue50};
    --footer-contact-icon-color: #{$blue50};
    --footer-social-icon-background: #{$default-secondary-base-color};
}

.Footer {
    .Footer-Columns.ContentWrapper {
        padding: 25px var(--content-padding);
        max-width: var(--content-wrapper-width);

        @include tablet {
            padding: 75px 72px 25px;
        }
    }

    ul,
    li {
        &:before {
            display: none;
        }

        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    h5 {
        color: var(--footer-header-color);
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        font-family: $font-primary;
        text-align: left;

        @include desktop {
            font-size: 15px;
        }
    }

    .CmsBlock-Wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        @include mobile {
            flex-direction: column;
        }
        @include desktop {
            flex-wrap: nowrap;
        }
    }

    &-Socials {
        h5 {
            @include tablet {
                margin-bottom: 30px;
            }
        }
        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;

            @include from-tablet {
                justify-content: flex-start;
                margin-top: 30px;
            }

            li {
                @include mobile {
                    margin: 0 5%;
                }

                @include tablet {
                    margin-right: 10%;
                }

                @include desktop {
                    margin-right: 5%;
                }
            }
        }

        &-Icon svg {
            width: 64px;
            height: 64px;
            fill: var(--footer-social-icon-background);

            path {
                fill: var(--footer-social-icon-background);
            }

            @include tablet {
                width: 57px;
                height: 57px;
            }

            @include desktop {
                width: 34px;
                height: 34px;
            }
        }
    }

    &-Contact {
        margin-top: 51px;
        text-align: left;
        @include from-tablet {
            margin-top: 0;
            padding-left: 20px;
        }

        ul li {
            margin: 30px 0 40px;
            position: relative;

            &:first-of-type > div {
                display: flex;
                justify-content: space-between;
                max-width: 200px;
                margin: 0 0 5px 0;
                font-family: $font-secondary;
                font-size: 13px;
                color: var(--footer-contact-color);
                opacity: 0.4;
                padding-left: 45px;
                line-height: 150%;
            }
        }

        p {
            padding-left: 45px;
            line-height: 150%;
            position: relative;

            a {
                font-weight: 400;
                color: var(--footer-contact-color);
                position: static;
            }
        }

        &-Icon {
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            fill: var(--footer-contact-icon-color);
            stroke: var(--footer-contact-icon-color);
        }

        &-Heading {
            margin-bottom: 15px;

            a {
                font-family: $font-secondary;
                font-size: 15px;
                color: $default-primary-base-color;
            }
        }
    }

    &-Additional {
        @include from-tablet {
            display: flex;
            width: 100%;
        }
        @include tablet {
            justify-content: space-between;
        }
        @include desktop {
            max-width: 45%;
        }

        @include wide-desktop {
            max-width: 40%;
        }

        > div {
            @include tablet {
                min-width: 270px;
            }
            @include desktop {
                width: 50%;
            }
        }
    }

    .Accordion {
        order: -1;
        flex-direction: row;

        @include desktop {
            margin-bottom: 0;
        }
        @include mobileAndTablet {
            order: 1;
            flex-direction: column;
        }

        .Accordion-Box {
            @include desktop {
                height: initial;
                width: 33.33%;
                border: 0;
                padding: 0;
            }
        }

        .Accordion-Header {
            @include desktop {
                cursor: default;
                font-size: 15px;
                padding: 0;
                line-height: 22px;
            }

            &:after {
                @include desktop {
                    display: none;
                }
            }
        }

        .Accordion-Text {
            a {
                color: var(--footer-column-link-color);
            }

            @include desktop {
                visibility: visible;
                margin-top: 30px;
            }
        }
    }
}
