@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.DeliveryCostPopup {
    &-Table {
        @include mobile {
            margin-top: 30px;
        }
    }

    &-Row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;

        &:not(:first-of-type) {
            border-top: solid 1px $grey5;
        }

        &:last-of-type {
            border-bottom: solid 1px $grey5;
        }

        @include mobileAndTablet {
            &:last-of-type {
                border-bottom: none;
            }
        }
    }

    &-Text {
        font-size: 15px;
        color: $black;
        font-weight: 400;

        &_isValue {
            color: $default-secondary-base-color;
        }

        &_isSubheader {
            color: $grey3;
            margin-top: -30px;

            @include mobileAndTablet {
                display: none;
            }
        }
    }

    .Popup-Header_isSubHeader {
        svg path {
            @include mobileAndTablet {
                stroke: $white;
            }
        }
    }
}
