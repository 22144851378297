@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.AdvoxFreeDelivery {
    &-Text svg path {
        fill: $black;
        stroke: unset;
    }

    &-Text {
        align-items: flex-start;
    }

    &-Value {
        font-weight: 600;
        color: $grey7;
    }

    &-Col {
        display: flex;
        flex-wrap: wrap;
        
        span {
            margin-left: 5px;
        }
    }
}
