@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --radio-button-accent-color: #{$grey3};
    --radio-button-accent-color-active: #{$orange50};
    --radio-button-accent-color-disabled: #{$grey2};
}

.RadioButtonIcon {
    width: 16px;
    height: 16px;
    &-Dot {
        transform: scale(0.85) translate(1.5px, 1px);
    }

    &-Circle {
        fill: var(--radio-button-accent-color);
    }

    &:hover,
    &_isActive {
        fill: var(--radio-button-accent-color-active);
        .RadioButtonIcon-Circle {
            fill: var(--radio-button-accent-color-active);
        }
    }
    &_isDisabled {
        &,
        &:hover {
            cursor: auto;
            .RadioButtonIcon-Circle {
                fill: var(--radio-button-accent-color-disabled);
            }
            .RadioButtonIcon-Dot {
                fill: var(--radio-button-accent-color-disabled);
                transform: scale(2) translate(-3.5px, -3.5px);
            }
        }
    }
}
